<template>
  <v-dialog v-model="show" persistent width="500">
    <v-card>
      <v-toolbar color="primary" dense flat dark>
        <v-toolbar-title>Validar Código</v-toolbar-title>
        <v-spacer></v-spacer>
        <v-btn icon @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar>
      <v-card-text class="mt-3">
        <div v-if="sendingCode" class="d-flex justify-center">
          <v-progress-circular
            indeterminate
            color="primary"
          ></v-progress-circular>
          <span class="ml-3 mt-2">Enviando código...</span>
        </div>
        <div v-else>
          <v-row dense>
            <v-col cols="12">
              <p>
                Se ha enviado un código de validación vía SMS al número
                telefónico registrado en la póliza. Ingrésalo para poder emitir
                el servicio
              </p>
            </v-col>

            <v-col cols="12">
              <v-otp-input
                v-model="formData.codigo"
                length="4"
                dark
              ></v-otp-input>
            </v-col>

            <v-col cols="12">
              <v-btn
                color="primary"
                block
                :disabled="formData.codigo.length < 4"
                :loading="loading"
                @click="validatePolicyCode"
              >
                <span>Validar código</span>
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import { mainAxios } from "../../mainAxios";

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    policy: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      loading: false,
      sendingCode: false,
      config: {
        headers: {
          Authorization: "Bearer " + localStorage.agenteAccessToken,
        },
      },
      formData: {
        codigo: "",
      },
    };
  },

  mounted() {
    this.sendConfirmationCode();
  },

  methods: {
    async sendConfirmationCode() {
      this.sendingCode = true;

      try {
        await mainAxios.get(
          `/v1/bradescard/sms/emision/uuid/${this.policy.uuid}`,
          this.config
        );
      } catch (error) {
        console.error(error);
      } finally {
        this.sendingCode = false;
      }
    },

    async validatePolicyCode() {
      this.loading = true;

      try {
        await mainAxios.post(
          `/v1/bradescard/validar/codigo/${this.policy.id}`,
          { codigo: parseInt(this.formData.codigo) },
          this.config
        );
        this.$toast.success("Póliza emitida");
        this.$emit("policySuccess");
      } catch (error) {
        this.$toast.error(
          "Error validando el código, por favor valida que sea correcto.",
          {
            position: "top-right",
            timeout: 5000,
            closeOnClick: true,
            pauseOnFocusLoss: true,
            pauseOnHover: true,
            draggable: true,
            draggablePercent: 0.6,
            showCloseButtonOnHover: false,
            hideProgressBar: true,
            closeButton: "button",
            icon: true,
            rtl: false,
          }
        );
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
